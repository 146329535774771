import { gql } from '@apollo/client';


export const GET_CONST_OPTIONS = gql`
  query GetConstOptions($label: ConstLabel!, $attribute: ConstAtt!, $jsonStr: String) {
    getConstOptions(label: $label, attribute: $attribute, jsonStr: $jsonStr)
  }
`;

export const GET_CONST_STORE = gql`
  query GetConstStore($label: ConstLabel!) {
    getConstStore(label: $label) {
      _id label 
      fields {
        _id name isUnique asFilter allowOther
      }
    }
  }
`;

export const UPDATE_CONST_STORE = gql`
  mutation UpdateConstStores($where: ConstStoreWhere, $update: ConstStoreUpdateInput) {
    updateConstStores(where: $where, update: $update) {
      constStores {
        _id
        fields {
          _id name isUnique asFilter allowOther
        }
      }
    }
  }
`;

export const UPDATE_CONST_FIELD = gql`
  mutation UpdateConstFields($where: ConstFieldWhere, $update: ConstFieldUpdateInput) {
    updateConstFields(where: $where, update: $update) {
      constFields {
        _id name isUnique asFilter allowOther
      }
    }
  }
`;

export const DELETE_CONST_FIELD = gql`
  mutation DeleteConstFields($where: ConstFieldWhere) {
    deleteConstFields(where: $where) {
      nodesDeleted
    }
  }
`;