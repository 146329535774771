import React from 'react';
import { IconButton, Tooltip, Dialog, DialogTitle  } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import ConstStoreSetting from './ConstStoreSetting';

//******************************************************************************
// component definition
//******************************************************************************
export default function SettingBtn({label}) {
  const [open, setOpen] = React.useState(false);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <>
      <Tooltip title="Setting">
        <IconButton onClick={handleOpen}>
          <SettingsIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {label} setting
        </DialogTitle>
        <ConstStoreSetting label={label} />
      </Dialog>
    </>
  )
}
