import React from 'react';
import { CardContent, TextField, Button, Grid, Autocomplete, Table, TableBody, TableRow, TableCell } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from "@apollo/client";
import HtmlEditor from "../ContentEditor/HtmlEditor";
import { CREATE_COURSE, UPDATE_COURSE, COURSES, COURSE } from '../../constants/gqls/courses';

const sectionOptions = ['Chinese', 'English', 'Putonghua/Mandarin', 'Foreign Language'];
const levelOptions = ['Undergraduate', 'Postgraduate'];
const natureOptions = ['Core', 'Elective', 'General Education', 'Supplementary'];

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  cell: {
    width: '1%',
    whiteSpace: 'nowrap',
  },
  labelText: {
    color: theme.palette.secondary.main
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function CreateOrEdit({course, then, mode}) {
  const classes = useStyles();
  const [title, setTitle] = React.useState(course?.title || '');
  const [code, setCode] = React.useState(course?.code || '');
  const [section, setSection] = React.useState(course?.section || '');
  const [level, setLevel] = React.useState(course?.level || '');
  const [nature, setNature] = React.useState(course?.nature || '');
  const [description, setDescription] = React.useState(course?.description || '');
  const [changed, setChanged] = React.useState(false);
  const [createCourse, { loading: createLoading }] = useMutation(CREATE_COURSE, {
    refetchQueries: [ COURSES ],
  });
  const [updateCourse, { loading: updateLoading }] = useMutation(UPDATE_COURSE, {
    refetchQueries: [ COURSE ],
  });
  // console.log(service);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onTitleChange = (event) => {
    setTitle(event.target.value);
    setChanged(true);
  }

  const onCodeChange = (event) => {
    setCode(event.target.value);
    setChanged(true);
  }

  const onSectionChange = (event, newValue) => {
    if (newValue !== section) {
      setSection(newValue);
      setChanged(true);
    }
  };

  const onLevelChange = (event, newValue) => {
    if (newValue !== level) {
      setLevel(newValue);
      setChanged(true);
    }
  };

  const onNatureChange = (event, newValue) => {
    if (newValue !== nature) {
      setNature(newValue);
      setChanged(true);
    }
  };

  const onDescriptionChange = (value) => {
    if (value !== description) {
      setDescription(value);
      setChanged(true);
    }
  }

  const save = () => {
    let cousrseTitle = title.trim();
    let courseCode = code.trim();
    let courseName = courseCode+' '+cousrseTitle;
    let courseDescription = description.trim();

    if (changed && cousrseTitle !== '' && courseCode !== '') {
      if (course) {
        // update
        updateCourse({ variables: {
          where: { _id: course._id },
          update: {
            title: cousrseTitle,
            code: courseCode,
            name: courseName,
            section,
            level,
            nature,
            description: courseDescription,
          }
        }}).then(res=>{
          console.log(res);
          if (then) then();
        });
      } else {
        createCourse({ variables: { input: {
          title: cousrseTitle,
          code: courseCode,
          name: courseName,
          section,
          level,
          nature,
          description: courseDescription,
        }}}).then(res=>{
          setTitle('');
          setCode('');
          setSection('');
          setLevel('');
          setNature('');
          setDescription('');
          if (then) then();
        });
      }
    } else if (!changed && then) {
      then();
    }
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <CardContent>
      { mode !== 'view' ?
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField fullWidth id="course-code" label="Course code"
                margin="dense" variant="outlined"
                value={code} onChange={onCodeChange}
                disabled={createLoading || updateLoading} />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth id="course-title" label="Course title"
                margin="dense" variant="outlined"
                value={title} onChange={onTitleChange}
                disabled={createLoading || updateLoading} />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete freeSolo id="course-section"
                options={sectionOptions}
                renderInput={(params) => <TextField {...params} label="Section" />}
                value={section} disabled={createLoading || updateLoading}
                onInputChange={onSectionChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete freeSolo id="course-level"
                options={levelOptions}
                renderInput={(params) => <TextField {...params} label="Level" />}
                value={level} disabled={createLoading || updateLoading}
                onInputChange={onLevelChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete freeSolo id="course-nature"
                options={natureOptions}
                renderInput={(params) => <TextField {...params} label="Nature" />}
                value={nature} disabled={createLoading || updateLoading}
                onInputChange={onNatureChange}
              />
            </Grid>
          </Grid>
        </>
        :
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={classes.cell}>
                <span className={classes.labelText}>Course code:</span>
              </TableCell>
              <TableCell>{course.code}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cell}>
                <span className={classes.labelText}>Course title:</span>
              </TableCell>
              <TableCell>{course.title}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cell}>
                <span className={classes.labelText}>Section:</span>
              </TableCell>
              <TableCell>{course.section}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cell}>
                <span className={classes.labelText}>Level:</span>
              </TableCell>
              <TableCell>{course.level}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cell}>
                <span className={classes.labelText}>Nature:</span>
              </TableCell>
              <TableCell>{course.nature}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      }
      <Grid container spacing={2}>
        <Grid item />
        <Grid item xs={12}>
          <p className={classes.labelText}>Description:</p>
        </Grid>
      </Grid>
      <HtmlEditor html={course?.description || ''}
        onChange={onDescriptionChange} disabled={createLoading || updateLoading}
        placeholder="Course description"
        readOnly={mode === 'view'} />
      { mode !== 'view' &&
        <div>
          <Button variant="contained" onClick={save} disabled={createLoading || updateLoading}>
            Save
          </Button>
        </div>
      }
    </CardContent>
  );
}
