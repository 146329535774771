import React from 'react';
import { Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from "@apollo/client";
import { SPACE_MEMBERS, SPACE_IMPORT_BY_EMAIL } from '../../constants/gqls/spaces';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialogContent-root.css-ypiqx9-MuiDialogContent-root' : {
      paddingTop: theme.spacing(2)
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function ImportMembersBySID({spaceId}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('');

  const [spaceImportByEmail, { loading }] = useMutation(SPACE_IMPORT_BY_EMAIL, {
    refetchQueries: [ SPACE_MEMBERS ],
  });

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleImport = () => {
    let emails = value.split('\n')
      .map(sid => sid.trim())
      .filter(sid => sid!=='')
      .map(sid => `${sid}@life.hkbu.edu.hk`);
    spaceImportByEmail({ variables: { spaceId, emails }}).then(() => {
      setOpen(false);
      setValue('');
    })
  };

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const onValueChange = (event) => {
    setValue(event.target.value);
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <>
      <Button variant="outlined" onClick={handleOpen}>
        Import by student ID
      </Button>
      <Dialog open={open} onClose={handleClose} className={classes.dialog}>
      <DialogTitle>
        Import members by student ID
      </DialogTitle>
      <DialogContent>
        <TextField label="Student IDs" multiline fullWidth rows={24}
          value={value} onChange={onValueChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton variant="outlined"
          loading={loading} onClick={handleImport}>
          Import
        </LoadingButton>
      </DialogActions>
    </Dialog>
    </>
  );
}
