import React from 'react';
import { TextField, Autocomplete, Chip } from '@mui/material';
import { useQuery } from "@apollo/client";
import { SEARCH_SERVICES } from '../../constants/gqls/services';


//******************************************************************************
// component definition
//******************************************************************************
export default function ServiceInput({value, onChange, label, multiple, disabled}) {
  const [inputValue, setInputValue] = React.useState(value? value.name : '');
  const { data } = useQuery(SEARCH_SERVICES, { variables: {
    where: { name_MATCHES: `(?i).*${inputValue || ''}.*`},
    options: { limit: 10 }
  }});

  React.useEffect(() => {
    if (data && value?.name === '') {
      // console.log(data.services);
      let currentValue = data.services.find((service)=>service.key===value.key);
      // console.log(currentValue);
      onChange(currentValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, data]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onValueChange = (event, newValue) => {
    // console.log(newValue);
    onChange(newValue);
  }

  const onInputChange = (event, newValue) => {
    // console.log(newValue);
    setInputValue(newValue);
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Autocomplete multiple={multiple} disabled={disabled}
      value={value} onChange={onValueChange}
      inputValue={inputValue} onInputChange={onInputChange}
      getOptionLabel={(option)=>option.name}
      options={ data ? data.services : [] }
      isOptionEqualToValue={(option, value)=>option._id===value._id}
      renderInput={(params) => <TextField {...params}
        label={label}
        variant="standard"
      />}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip variant="outlined" label={option.name} {...getTagProps({ index })} />
        ))
      }
    />
  );
}
