import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import ShortTextIcon from '@mui/icons-material/ShortText';
import KeyIcon from '@mui/icons-material/Key';
import NotesIcon from '@mui/icons-material/Notes';
// import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import StorageIcon from '@mui/icons-material/Storage';
import EventNoteIcon from '@mui/icons-material/EventNote';
import FilterListIcon from '@mui/icons-material/FilterList';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ConstructionIcon from '@mui/icons-material/Construction';
// import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { useQuery } from "@apollo/client";
import { makeStyles } from '@material-ui/core/styles';
import InputImportFormDialog from './InputImportFormDialog';
import ValueSetInputDialog from './ValueSets/ValueSetInputDialog';
import { ME } from '../../constants/gqls/user';



function getTypeOptions(inData) {
  let typeOptions = [
    { icon: <ShortTextIcon />, text: "Short answer", value: "shortText" },
    { icon: <KeyIcon />, text: "Unique key", value: "uniqueKey" },
    { icon: <NotesIcon />, text: "Paragraph", value: "paragraph" },
    // { icon: <ToggleOffIcon />, text: "Switch", value: "switch" },
    { icon: <RadioButtonCheckedIcon />, text: "Radio", value: "radio" },
    { icon: <CheckBoxIcon />, text: "Checkbox", value: "checkbox" },
    { icon: <ArrowDropDownCircleIcon />, text: "Dropdown", value: "dropdown" },
  ]
  let staffOptions = [
    { icon: <EventNoteIcon />, text: "Weekly Hour", value: "weeklyHour" },
    { icon: <EventNoteIcon />, text: "Weekly Hour Multiple", value: "weeklyHourMulti" },
    { icon: <CastForEducationIcon />, text: "Tutoring Hour", value: "tutoringHour" },
    { icon: <FilterListIcon />, text: "Application Filter", value: "applicationFilter" },
    { icon: <PeopleAltIcon />, text: "Select Students", value: "selectStudents" },
  ]
  let adminOptions = [
    { icon: <StorageIcon />, text: "Value Sets", value: "valueSets" },
    { icon: <StorageIcon />, text: "Value Sets Selected", value: "valueSetsSelected" },
    { icon: <StorageIcon />, text: "Value Sets Select Single", value: "valueSetSingle" },
    { icon: <UploadFileIcon />, text: "File upload", value: "upload" },
    { icon: <UploadFileIcon />, text: "File upload Multiple", value: "uploadMulti" },
    // { icon: <ConstructionIcon />, text: "Property value", value: "propertyValue" },
    // { icon: <ConstructionIcon />, text: "Fixed value", value: "fixedValue" },
  ]
  
  let roles = inData?.me?.roles;
  if (roles && (roles.includes('admin'))) {
    return typeOptions.concat(staffOptions).concat(adminOptions);
  }
  if (roles && (roles.includes('staff'))) {
    return typeOptions.concat(staffOptions);
  }
  return typeOptions;
}

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  typeOption: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: theme.spacing(1),
    }
  },
  dialogText: {
    '&.MuiDialogContentText-root': {
      marginBottom: theme.spacing(2),
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function InputTypeSetting({value, importFormId, service, valueSetInput, updateInput, setFocus, loading}) {
  const classes = useStyles();
  const { data } = useQuery(ME);
  const [open, setOpen] = React.useState(false);
  const [openVS, setOpenVS] = React.useState(false);
  const [importType, setImportType] = React.useState(null);
  const typeOptions = getTypeOptions(data);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleChange = (event) => {
    if (event.target.value === 'tutoringHour' 
      || event.target.value === 'applicationFilter'
      || event.target.value === 'selectStudents') {
      setImportType(event.target.value);
      setOpen(true);
    } else if (event.target.value === 'valueSetsSelected' 
      || event.target.value === 'valueSetSingle') {
      setImportType(event.target.value);
      setOpenVS(true);
    } else {
      updateInput({
        type: event.target.value
      });
    }
  };

  const handleBlur = () => {
    setFocus(false);
  }

  const handleFocus = () => {
    setFocus(true);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseVS = () => {
    setOpenVS(false);
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <FormControl disabled={loading} fullWidth>
        <InputLabel>Input Type</InputLabel>
        <Select size="small" label="Input Type"
          value={value} onChange={handleChange}
          onBlur={handleBlur} onFocus={handleFocus}
        >
          {typeOptions.map(option=>
            <MenuItem key={option.value} value={option.value}>
              <div className={classes.typeOption}>
                {option.icon}
                <div>{option.text}</div>
              </div>
            </MenuItem>
          )}
        </Select>
      </FormControl>
      <InputImportFormDialog open={open} handleClose={handleClose}
        loading={loading}
        importFormId={importFormId}
        service={service}
        updateInput={updateInput}
        importType={importType} />
      <ValueSetInputDialog open={openVS} handleClose={handleCloseVS}
        valueSetInput={valueSetInput}
        type={importType}
        loading={loading} 
        updateInput={updateInput} />
    </React.Fragment>
  )
}
