import React from 'react';
import { useParams } from "react-router-dom";
import { LinearProgress, Container, Paper, CardHeader, CardContent } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from "@apollo/client";
import NotFount from './NotFount';
import DataTable from '../components/Forms/DataTable';
import ClearFormData from '../components/Forms/ClearFormData';
import { SUBMISSIONS_DATA } from '../constants/gqls/forms';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3)
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function FormData() {
  const classes = useStyles();
  const params = useParams();
  const { loading, data } = useQuery(SUBMISSIONS_DATA, { variables: {
    docId: params.id,
    includePreSem: true
  }});
  console.log(data);


  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return <LinearProgress />;
  if (!data) return 'Network error.'
  if (!data.document) return <NotFount />

  return (
    <Container maxWidth={false} className={classes.container}>
      <Paper>
        <CardHeader title={`${data.document.title} submitted data`} />
        <DataTable tableName={data.document.title} data={data} />
        <CardContent>
          <ClearFormData docId={params.id} />
        </CardContent>
      </Paper>
    </Container>
  );
}
