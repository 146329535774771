import React from 'react';
import { useLazyQuery } from "@apollo/client";
import { Autocomplete, TextField } from '@mui/material';
import { GET_CONST_OPTIONS } from "../../../constants/gqls/constStore"


//******************************************************************************
// component definition
//******************************************************************************
export default function ValueFieldAutoComplete({valueField, disabled, update, value, setText, timer, optionFilters}) {
  const [freeSolo, setFreeSolo] = React.useState(false);
  const [options, setSetOptions] = React.useState(valueField.options || []);
  const [getConstOptions] = useLazyQuery(GET_CONST_OPTIONS, {
    onCompleted: (data) => {
      console.log(data);
      setSetOptions(data.getConstOptions);
    }
  });  

  React.useEffect(() => {
    setSetOptions(valueField.options || []);
    setFreeSolo(true);
    let [, label, attribute] = valueField.inputType.split('_');

    if (label && label !== 'freeSolo') {
      let jsonStr = undefined;
      console.log(optionFilters);
      if (optionFilters[label]) {
        jsonStr = JSON.stringify(optionFilters[label]);
      }
      getConstOptions({ variables: { label, attribute, jsonStr }});
    }
  }, [valueField, optionFilters]);

  // React.useEffect(() => {
  //   if (value && label && attribute) {
  //     let newOF = { ...optionFilters };
  //     if (newOF[label]) {
  //       newOF[label][attribute] = value
  //     } else {
  //       newOF[label] = { [attribute]: value }
  //     }
  //     console.log(newOF);
      
  //     setOptionFilters(newOF);
  //   }
  // }, [value, label, attribute]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleChange = (event, newValue) => {
    if (newValue !== value) {
      clearTimeout(timer.current);
      setText(newValue);
      timer.current = setTimeout(()=>{
        update(valueField._id, newValue).then(res=>{
          // inputRef.current.focus();
        });
      }, 1000);
    }
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Autocomplete freeSolo={freeSolo} size="small"
      options={options}
      renderInput={(params) => <TextField {...params} label={valueField.name} />}
      value={value} disabled={disabled}
      onInputChange={handleChange}
    />
  )
}
