import React from 'react';
import { TextField, Accordion, AccordionSummary, Typography, AccordionDetails, Grid, Button} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import TableWhereEdit from './TableWhereEdit';
import ColumnEdit from './ColumnEdit';
import FileUpload from '../Files/FileUpload';
import FileDeleteBtn from '../Files/FileDeleteBtn';


//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  accordion: {
    '& .MuiAccordion-root': {
      backgroundColor: theme.palette.primary.pale
    },
    // maxHeight: '50vh',
    // overflow: 'auto',
    paddingBottom: theme.spacing(2)
  },
  btnGrid: {
    display: 'flex',
    '& .MuiButton-root': {
      margin: 'auto'
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function TableParmsEdit({dataTableId, reportType, dataFileId, where, setWhere, queryFields, setQueryFields, options, setOptions, addColumn, columns, addColumns, setChanged, disabled, refetch}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(reportType==='CSV file'? 'tableColumns' : 'where');
  // console.log(dataFileId);


  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onQueryFieldsChange = (event) => {
    setQueryFields(event.target.value);
    setChanged(true);
  }

  const onOptionsChange = (event) => {
    setOptions(event.target.value);
    setChanged(true);
  }

  const handleClick = (inOpen) => {
    setOpen(inOpen);
  }

  const handleAddColumns = () => {
    let fields = queryFields.split('\n')[0]
                    .trim().split(' ').filter(item=>item!=='_id');
    console.log(fields);
    addColumns(fields);
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <div className={classes.accordion}>
      { reportType === 'CSV file' && 
        <Accordion expanded={open==='file'} onChange={()=>handleClick('file')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>File</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <FileUpload hasFile={dataFileId}
                  systemFileType="dataFile"
                  relatedId={dataTableId}
                  fileTypes={["text/csv"]}
                  refetch={refetch}>
                    Download file
                </FileUpload>
              </Grid>
              { dataFileId && 
                <Grid item>
                  <FileDeleteBtn fileId={dataFileId} refetch={refetch} />
                </Grid>
              }
            </Grid>
          </AccordionDetails>
        </Accordion>
      }
      { reportType !== 'CSV file' &&
        <>
          <Accordion expanded={open==='where'} onChange={()=>handleClick('where')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Where</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableWhereEdit reportType={reportType}
                  where={where} setWhere={setWhere}
                  setChanged={setChanged}
                  disabled={disabled} />
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={open==='options'} onChange={()=>handleClick('options')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Query options</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField fullWidth multiline rows={4} variant="outlined"
                  id="query-options" label="Query options" 
                  value={options} onChange={onOptionsChange} />
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={open==='queryFields'} onChange={()=>handleClick('queryFields')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Select fields</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField fullWidth multiline rows={10} variant="outlined"
                  id="query-fields" label="Query fields" 
                  value={queryFields} onChange={onQueryFieldsChange} />
            </AccordionDetails>
          </Accordion>
        </>
      }
      <Accordion expanded={open==='tableColumns'} onChange={()=>handleClick('tableColumns')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Table columns</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            <Grid item xs={12} className={classes.btnGrid}>
              <Button size='small' variant="outlined" onClick={()=>{addColumn()}}>
                Add column
              </Button>
              { columns.length===0 && 
                <Button size='small' variant="outlined" onClick={handleAddColumns}>
                  Add columns for all fields
                </Button>
              }
            </Grid>
            {columns.map(column=>(
              <ColumnEdit key={column._id} column={column} addColumn={addColumn} />
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
