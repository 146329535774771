import React from 'react';
import clsx from 'clsx';
import { Grid, Divider, Button, TextField, Tooltip } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from "@apollo/client";
import FunctionBtns from './FunctionBtns';
import DateTimeInput from './DateTimeInput';
import HtmlEditor from "../ContentEditor/HtmlEditor";
import { DateToString } from '../../constants/utils';
import { UPDATE_ATTENDANCE } from '../../constants/gqls/attendances';
import { SERVICE_SESSION } from '../../constants/gqls/serviceSessions';
import { extractHtmlContent } from '../../constants/utils';
import ParticipantEdit from './ParticipantEdit';
import StudentPrivateNote from './StudentPrivateNote';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  root: {
    '& hr':{
      margin: `${theme.spacing(2)}px 0`
    }
  },
  note: {
    fontFamily: 'inherit'
  },
  labelText: {
    color: theme.palette.secondary.main
  },
  btns: {
    marginTop: theme.spacing(2),
    display: 'flex',
    '& button': {
      margin: 'auto'
    }
  },
  space: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  lineBreak: {
    paddingTop: theme.spacing(1)
  },
  // tooltip: {
  //   [`& .${tooltipClasses.tooltip}`]: {
  //     backgroundColor: '#fff',
  //     color: 'rgba(0, 0, 0, 0.87)',
  //     boxShadow: theme.shadows[1],
  //     fontSize: 11,
  //   },
  // },
}));

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.error.main,
    boxShadow: theme.shadows[1],
    fontSize: 18,
    fontWeight: 'bold',
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function AttendanceItem({ attendance, sParticipants, index, isProvider, providerRoles, myRole }) {
  const classes = useStyles();
  const [editMode, setEditMode] = React.useState(false);
  const [saveClicked, setSaveClicked] = React.useState(false);
  const [hasOldNote, setHasOldNote] = React.useState(false);
  const [note, setNote] = React.useState('');
  const [privateNote, setPrivateNote] = React.useState('');
  const [hours, setHours] = React.useState(attendance.hours);
  const [pList, setPList] = React.useState([]);
  const [submitErr, setSubmitErr] = React.useState(null);
  const [updateAttendance, { loading }] = useMutation(UPDATE_ATTENDANCE, {
    refetchQueries: [ SERVICE_SESSION ],
  });
  // console.log(sParticipants);
  // console.log(extractContent(attendance.note));

  React.useEffect(() => {
    if (attendance.note) {
      setNote(attendance.note);
      setHasOldNote(extractHtmlContent(attendance.note).trim().length > 0);
    } else {
      setHasOldNote(false);
    }

    if (attendance.privateNote) {
      setPrivateNote(attendance.privateNote)
    }
    let newList = [...attendance.participants];
    newList.sort((a,b)=>a.email<b.email? 1 : -1);
    // console.log(newList);
    setPList(newList);
  }, [attendance]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const editClicked = () => {
    setEditMode(!editMode);
  }

  const handleNoteChange = (value) => {
    if (value !== note) {
      setNote(value);
    }
  }

  const handlePrivateNoteChange = (value) => {
    if (value !== privateNote) {
      setPrivateNote(value);
    }
  }

  const handleHoursChange = (event) => {
    setHours(event.target.value)
  }

  const handleUpdate = (inUpdate) => {
    return updateAttendance({ variables: {
      id: attendance._id,
      inUpdate
    }});
  }

  const save = () => {
    setSaveClicked(true);

    handleUpdate({ note, privateNote,
      hours: parseFloat(hours) 
    }).then(() =>{
      setSubmitErr(null);
      setSaveClicked(false);
      setEditMode(false);
    });
  }

  const submit = () => {
    handleUpdate({ submitted: true }).then(() =>{
      setSubmitErr(null);
    }, err=>{
      // console.log(err.message);
      setSubmitErr(err.message);
    });
  }


  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <div className={classes.root}>
      { index !== 0 && <Divider />}
      <Grid container alignItems="center" spacing={2} >
        <Grid item xs="auto" className={classes.labelText}>
          Time:
        </Grid>
        <Grid item xs>
          { editMode ?
            <DateTimeInput attendance={attendance}
              handleUpdate={handleUpdate} loading={loading} />
            : `${DateToString(attendance.date)}, ${attendance.time}`
          }
        </Grid>
        { !attendance.submitted &&
          <Grid item xs="auto">
            Drafting
          </Grid>
        }
        { attendance.deleteable &&
          <Grid item xs="auto">
            <FunctionBtns attendance={attendance} editClicked={editClicked} />
          </Grid>
        }
      </Grid>
      <Grid container alignItems="center" spacing={3} >
        <Grid item xs="auto" className={classes.labelText}>
          Hours:
        </Grid>
        <Grid item xs container alignItems="center" spacing={3}>
          { editMode ? 
            <Grid item>
              <TextField id="attendance-hours" variant="standard"
                disabled={loading}
                type="number" min="0.5" step="0.5"
                value={hours} onChange={handleHoursChange}  />
            </Grid>
            : <Grid item>{attendance.hours}</Grid>
          }
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={3} className={classes.lineBreak}>
        <Grid item xs="auto" className={classes.labelText}>
          Participants:
        </Grid>
        <Grid item xs container alignItems="center" spacing={3}>
          { editMode ? 
            <Grid item>
              <ParticipantEdit options={sParticipants} 
                participants={attendance.participants}
                update={handleUpdate}
                providerRoles={providerRoles}
                myRole={myRole} />
            </Grid>
            : pList.map(pp=>(
              <Grid item xs="auto" key={pp._id}>
                {pp.name}
              </Grid>
            ))
          }
        </Grid>
      </Grid>
      { attendance.sPrivateInfo.map(spInfo=>(
        <div key={spInfo._id}>
          <div className={clsx(classes.labelText, classes.space)}>
            Comment for {spInfo.student.name}:
          </div>
          <StudentPrivateNote 
            studentPrivateInfo={spInfo}
            refId={attendance._id} 
            saveClicked={saveClicked} 
            editMode={editMode} />
        </div>
      ))}
      { hasOldNote && 
        <>
          <div className={clsx(classes.labelText, classes.space)}>
            Report:
          </div>
          <HtmlEditor placeholder="Report or remarks goes here"
            html={attendance.note} onChange={handleNoteChange}
            readOnly={!editMode} disabled={loading} />
        </>
      }

      { isProvider && 
        <>
          <div className={clsx(classes.labelText, classes.space)}>
            Note for admin only:
          </div>
          <HtmlEditor placeholder="Note here can not be seen by service client"
            html={attendance.privateNote} onChange={handlePrivateNoteChange}
            readOnly={!editMode} disabled={loading} />
        </>
      }
      <div className={classes.btns}>
        { editMode?
          <>
            <Button onClick={editClicked}>
              Cancel
            </Button>
            <Button variant="contained" onClick={save} disabled={loading}>
              Save
            </Button>
          </>
          :
          !attendance.submitted &&
            <LightTooltip title={submitErr} placement="top"
              open={Boolean(submitErr)}
              disableFocusListener
              disableHoverListener
              disableTouchListener>
              <Button variant="outlined" onClick={submit} disabled={loading}>
                Submit
              </Button>
            </LightTooltip>
        }
      </div>
    </div>
  );
}
