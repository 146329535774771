import React from 'react';
import { useSearchParams } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import { Dialog, DialogTitle, DialogContent, DialogContentText,
  DialogActions, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from "@apollo/client";
import Captcha from '../InputsCommt/Captcha';
import { formFilledCheck, dataMap } from '../../constants/cache';
import { SUBMIT, GUEST_SUBMIT } from '../../constants/gqls/forms';
import { DOCUMENT_ISFORM } from '../../constants/gqls/documents';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(2),
    borderTop: '1px dashed '+theme.palette.secondary.main,
  },
  btn: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function SubmitBtn({submitPrompt, showSubmitBtn, docId, pageMode}) {
  const classes = useStyles();
  const checkList = useReactiveVar(formFilledCheck);
  const [searchParams] = useSearchParams();
  const [open, setOpen] = React.useState(false);
  const [filledOK, setFilledOK] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [captchaLoaded, setCaptchaLoaded] = React.useState(false);
  const [captchaToken, setCaptchaToken] = React.useState(null);
  const [captchaText, setCaptchaText] = React.useState('');
  const [submit, { loading }] = useMutation(SUBMIT, {
    refetchQueries: [ DOCUMENT_ISFORM ]
  });
  const [guestSubmit, { loading: guestLoading }] = useMutation(GUEST_SUBMIT);
  // console.log(submitPrompt);

  React.useEffect(() => {
    let checked = true;
    for (const property in checkList) {
      if (!checkList[property]) {
        checked = false;
      }
    }
    setFilledOK(checked);
  }, [checkList]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleSubmit = () => {
    if (!pageMode) {
      // setOpen(true);
      submit({ variables: {
        subId: searchParams.get("submission")
      }}).then(res=>{
        // console.log(res);
        setOpen(true);
      }, err=>{
        // console.log(err.message);
        setError(err.message);
      });
    } else {
      // for guest user
      // console.log(dataMap());
      // console.log(Array.from(dataMap(), ([_, v])=>v));
      let dataInput = Array.from(dataMap(), ([_, v])=>v).map(d=>({
        value: d.value,
        values: d.values,
        boolValue: d.boolValue,
        options: { connect: { where: {
          node: { _id_IN: d.options.map(opt=>opt._id) }
        }}},
        input: { connect: { where: {
          node: { _id: d.input._id }
        }}},
      }));
      setCaptchaLoaded(false);
      guestSubmit({ variables: {
        data: dataInput,
        captchaToken,
        captchaText
      }}).then(res=>{
        // console.log(res);
        if (res.data.guestSubmit) {
          dataMap(null);
          setOpen(true);
        } else {
          setError("Captcha verification failed. Please try again.");
        }
      }, err=>{
        // console.log(err.message);
        setError(err.message);
      });
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleErrorClose = () => {
    setError(null);
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <div>
      { showSubmitBtn &&
        <div className={classes.root}>
          { pageMode &&
            <Captcha params={{
                action: 'formSubmit',
                args: docId,
              }}
              setToken={setCaptchaToken} 
              setValue={setCaptchaText}
              value={captchaText}
              loaded={captchaLoaded}
              setLoaded={setCaptchaLoaded} />
          }
          <div className={classes.btn}>
            <LoadingButton variant="contained" disabled={!filledOK || (pageMode && !captchaText)}
              onClick={handleSubmit} loading={loading || guestLoading}
            >
              Submit
            </LoadingButton>
          </div>
        </div>
      }
      { submitPrompt && submitPrompt.promptTemplate &&
        <Dialog
          open={open}
          onClose={handleClose}
        >
          <DialogTitle>Submitted</DialogTitle>
          <DialogContent>
            {submitPrompt.promptTemplate.split('\n').map((p, i)=>(
              <DialogContentText key={i}>{p}</DialogContentText>
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      }
      <Dialog
        open={Boolean(error)}
        onClose={handleErrorClose}
      >
        <DialogTitle>Submit error</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <pre>
              {error}
            </pre>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleErrorClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
