import React from 'react';
import { Dialog, DialogTitle, DialogContent,
  RadioGroup, FormControlLabel, Radio } from '@mui/material';

//******************************************************************************
// component definition
//******************************************************************************
export default function SelectBoardDialog({board, setBoard, open, handleClose, entity}) {

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleChange = (event) => {
    setBoard(event.target.value);
    handleClose();
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        Select Board
      </DialogTitle>
      <DialogContent>
      <RadioGroup name="select-board"
        value={board}
        onChange={handleChange}
      >
        <FormControlLabel value="self" control={<Radio />} label={"Self: view by "+{
          space: "space members",
          person: "myself"
        }[entity]} />
        <FormControlLabel value="system" control={<Radio />} label="System: view by other system users" />
        <FormControlLabel value="public" control={<Radio disabled />} label="Public: view by public users (comming soon)" />
      </RadioGroup>
      </DialogContent>
    </Dialog>
  )
}
