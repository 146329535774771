import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { List, LinearProgress } from '@mui/material';
import { useQuery } from "@apollo/client";
import CourseItem from './CourseItem';
import { COURSES } from '../../constants/gqls/courses';
const limit = 10;

//******************************************************************************
// component definition
//******************************************************************************
export default function CoursesList() {
  const { loading, data, fetchMore } = useQuery(COURSES, {
    variables: { options: { limit, offset: 0 }}
  });

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const loadMore = () => {
    let offset = data.courses.length
    // console.log(offset);
    fetchMore({ variables: {
      options: { limit, offset }
    }});
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return <LinearProgress />;
  if (!data) return 'Network error.'
  if (data.coursesCount === 0) {
    return 'No course yet.';
  }

  return (
    <InfiniteScroll
        loadMore={loadMore}
        hasMore={data.coursesCount > data.courses.length}
        loader={<LinearProgress key="loading" />}
    >
      <List>
        { data.courses.map(course=>(
          <CourseItem key={course._id} course={course} />
        )) }
      </List>
    </InfiniteScroll>
  );
}
