import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { List, CardContent, LinearProgress } from '@mui/material';
import { useQuery, useMutation } from "@apollo/client";
// import { SPACE } from '../../constants/gqls/spaces';
import MembersAddInputs from '../Space/MembersAddInputs';
import MemberItem from '../Space/MemberItem';
import SearchInput from '../InputsCommt/SearchInput';
import { SPACE_MEMBERS, UPDATE_SPACE_MEMBERS } from '../../constants/gqls/spaces';
const limit = 10;

//******************************************************************************
// component definition
//******************************************************************************
function MembersList({space, edit, search, handleUpdate}) {
  const { loading, data, fetchMore } = useQuery(SPACE_MEMBERS, { variables: {
    spaceId: space._id,
    search,
    offset: 0,
    limit
  }});

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const loadMore = () => {
    fetchMore({ variables: {
      limit,
      offset: data.spaceMembers.length
    }});
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return <LinearProgress />;
  if (!data) return 'Network error.'
  if (data.spaceMembersCount === 0) return 'No records...'

  return (
    <InfiniteScroll
      loadMore={loadMore}
      hasMore={data.spaceMembersCount > data.spaceMembers.length}
      useWindow={false}
      loader={<LinearProgress key="loading" />}
    >
      <List>
        { data.spaceMembers.map(item=>(
          <MemberItem key={item._id} edit={
            edit && !(space.mgrCount === 1 && item.role === 'Manager')
          } item={item} handleUpdate={handleUpdate} loading={loading} />
        )) }
      </List>
    </InfiniteScroll>
  );
}

export default function MembersCard({space, edit}) {
  const [search, setSearch] = React.useState('');
  const [updateSpaceMembers, { loading: updateLoading }] = useMutation(UPDATE_SPACE_MEMBERS, {
    refetchQueries: [ SPACE_MEMBERS ],
  });

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleUpdate = (update, then) => {
    updateSpaceMembers({ variables: {
      spaceId: space._id,
      // sort: [{ edge: { role: 'ASC' }}],
      ...update
    }}).then(res => {
      // console.log(res);
      if (then) {
        then();
      }
    });
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      { edit ?
        <CardContent>
          <MembersAddInputs space={space} handleUpdate={handleUpdate} loading={updateLoading} />
        </CardContent>
        : 
        <CardContent>
          <SearchInput value={search} onChange={setSearch} />
        </CardContent>
      }
      <MembersList space={space} edit={edit} search={search}
        handleUpdate={handleUpdate} />
    </React.Fragment>
  );
}
