import React from 'react';
import { Container, Paper, CardHeader } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from "@apollo/client";
import SettingBtn from '../components/ConstStore/SettingBtn';
import CreateBtn from '../components/Courses/CreateBtn';
import ImportBtn from '../components/Courses/ImportBtn';
import CoursesList from '../components/Courses/CoursesList';
import { ME } from '../constants/gqls/user';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3)
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function Courses() {
  const { data } = useQuery(ME);
  const classes = useStyles();

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------


  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Container maxWidth="xl" className={classes.container}>
      <Paper>
        <CardHeader title="Courses" action={
            data?.me?.roles.includes('admin') && 
            <>
              <ImportBtn />
              <CreateBtn />
              <SettingBtn label={"Course"} />
            </>
          } />
        <CoursesList />
      </Paper>
    </Container>
  );
}
