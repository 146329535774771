import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, Grid } from '@mui/material';
import MeetingWeeklyTimeInput from './MeetingWeeklyTimeInput';
import MeetingDateTimeInput from './MeetingDateTimeInput';


//******************************************************************************
// component definition
//******************************************************************************
export default function MeetingTimeInput({value, onChange, loading, type}) {
  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  switch (type) {
    case 'nonrepeatable':
      return <MeetingDateTimeInput value={value}
        onChange={onChange}
        loading={loading} />
  
    default:
      return <MeetingWeeklyTimeInput value={value} 
        onChange={onChange}
        loading={loading} />
  }
}
