import React from 'react';
import { Grid } from '@mui/material';
import DateInput from '../InputsCommt/DateInput';
import TimeInput from '../ServiceSessions/TimeInput';


//******************************************************************************
// component definition
//******************************************************************************
export default function DateTimeInput({ attendance, handleUpdate, loading }) {

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleDateChange = (date) => {
    handleUpdate({ date });
  }

  const handleTimeChange = (time) => {
    handleUpdate({ time });
  }


  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Grid container spacing={2}>
      <Grid item xs="auto">
        <DateInput id="attendance-date" disabled={loading}
          value={attendance.date} onChange={handleDateChange} />
      </Grid>
      <Grid item xs="auto">
        <TimeInput loading={loading}
          value={attendance.time} handleChange={handleTimeChange} />
      </Grid>
    </Grid>
  );
}
