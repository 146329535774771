import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { useLazyQuery, useQuery } from "@apollo/client";
import { EXPORT_FORM_TO_PDF } from "../../constants/gqls/documents";
import { gql } from '@apollo/client';

const DOCUMENT = gql`
  query Document($docId: ID!) {
    document(id: $docId) {
      _id hasPdfTemplate
    }
  }
`;

//******************************************************************************
// component definition
//******************************************************************************
export default function FormExportBtn({formId, applicantId}) {
  const { data } = useQuery(DOCUMENT, {variables: { docId: formId }});
  console.log(data);

  const [getFileName, { loading }] = useLazyQuery(EXPORT_FORM_TO_PDF, {
    variables: { formId, applicantId },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      console.log(data);
      let downloadLink = process.env.REACT_APP_DOWNLOAD_URI+data.exportFormToPDF;
      console.log(downloadLink);
      let link = document.createElement('a');
      link.download = '';
      link.href = downloadLink;
      link.click();
    }
  });

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleClick = () => {
    getFileName();
  };


  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <LoadingButton variant="contained"
      loading={loading} 
      onClick={handleClick} 
      disabled={!data?.document?.hasPdfTemplate}>
      Export to PDF
    </LoadingButton>
  )
}
