import React from 'react';
import { TextField } from '@mui/material';

//******************************************************************************
// component definition
//******************************************************************************
export default function FormQuota({quota, onChange, loading}) {
  const [value, setValue] = React.useState('');
  const [changed, setChanged] = React.useState(false);

  React.useEffect(() => {
    setValue(quota===null? '' : quota.toString());
  }, [quota]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleChange = (event) => {
    setValue(event.target.value)
    setChanged(true);
  }

  const handleBlur = (event) => {
    if (changed) {
      let newValue = null;
      if (event.target.value) {
        newValue = parseInt(event.target.value);
      }
      onChange(newValue).then(res => {
        setChanged(false);
      });
    }
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <TextField fullWidth id="form-quota" variant="outlined"
      type="number" label="Quota limited" disabled={loading}
      value={value} onChange={handleChange} onBlur={handleBlur} />
  );
}
