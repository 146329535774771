import React from 'react';
import { valueSetToString } from '../../constants/utils';
import SortValueSets from './SortValueSets';


//******************************************************************************
// component definition
//******************************************************************************
export default function InputDataView({valueFields, viewValueFields, singleMode, data, openEdit, setSortOpen}) {
  // const [html, setHtml] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  // console.log(item.input.type);
  // if (singleMode) {
  //   console.log(data);
  //   if (data) {
  //     console.log(valueSetToString(data.valueSetList[0], viewValueFields));
  //   }
  // }

  React.useEffect(() => {
    if (setSortOpen) {
      setSortOpen(Boolean(anchorEl));
    }
  }, [anchorEl, setSortOpen]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleSortOpen = (event) => {
    if (data?.valueSetList?.length > 1) {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
    }
  };

  const handleSortClose = () => {
    setAnchorEl(null);
  };


  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <>
      <div onContextMenu={handleSortOpen}>
        {data?.valueSetList[0] ? 
          (singleMode?
            <div>
              <div onClick={()=>openEdit(0, data.valueSetList[0]._id)}>
                <div dangerouslySetInnerHTML={{ 
                  __html: valueSetToString(data.valueSetList[0], viewValueFields)
                }} />
              </div>
            </div> 
            :
            <ol>
              {data?.valueSetList.map((vs, index)=>(
                <div key={index}>
                  <li onClick={()=>openEdit(index, vs._id)}>
                    <div dangerouslySetInnerHTML={{ 
                      __html: valueSetToString(vs, viewValueFields)
                    }} />
                  </li>
                </div>
              ))}
            </ol>)
          : <div onClick={()=>openEdit()}><p>Click to start</p></div>
        }
      </div> 
      <SortValueSets
        anchorEl={anchorEl}
        onClose={handleSortClose}
        data={data}
        valueFields={valueFields} />
    </>
  );
}
