import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, Grid } from '@mui/material';


function getTimeOption() {
  let options = [];
  for (var start = 8; start < 20; start++) {
    // options.push(`${start}:00 ~ ${start+1}:00`);
    // options.push(`${start}:30 ~ ${start+1}:30`);
    options.push(`${start}:00`);
    options.push(`${start}:30`);
  }
  return options;
}

const timeOptions = getTimeOption();

//******************************************************************************
// component definition
//******************************************************************************
export default function TimeInput({value, handleChange, loading}) {
  const [from, to] = value? value.split(' ~ ') : ['', ''];

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleFromChange = (event) => {
    handleChange([event.target.value, to].join(' ~ '));
  };
  
  const handleToChange = (event) => {
    handleChange([from, event.target.value].join(' ~ '));
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item xs>
        <FormControl fullWidth disabled={loading}>
          <InputLabel>From time</InputLabel>
          <Select label="From time"
            value={from} onChange={handleFromChange}
          >
            { timeOptions.map(option=>
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs="auto">
        ~
      </Grid>
      <Grid item xs>
        <FormControl fullWidth disabled={loading}>
          <InputLabel>To time</InputLabel>
          <Select label="To time"
            value={to} onChange={handleToChange}
          >
            { timeOptions.map(option=>
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
}
