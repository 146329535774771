import React from 'react';
import { TextField, Grid, FormControlLabel, Switch, FormControl, InputLabel, Select, MenuItem, Collapse, IconButton } from '@mui/material';
import NotesIcon from '@mui/icons-material/Notes';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useMutation } from "@apollo/client";
import ValueFieldText from './ValueFieldText';
import FuntionBtn from './KeyFnBtn';
import { DELETE_VALUE_FIELD, UPDATE_VALUE_FIELD } from '../../../constants/gqls/dataValues';
import { isTypeAutoComplete } from '../../../constants/utils'

const valueFieldIndicators = {
  required: '*',
  multiline: <NotesIcon />,
  maxTextLength: 'Max',
  autoComplete: <ArrowDropDownCircleIcon />,
  date: <CalendarMonthIcon />
}

//******************************************************************************
// component definition
//******************************************************************************
export default function ValueField({valueField, count, inputId}) {
  const [maxTextLength, setMaxTextLength] = React.useState(valueField.maxTextLength || '');
  const [deleteKey, { loading:deleteLoading }] = useMutation(DELETE_VALUE_FIELD);
  const [updateKey, { loading:updateLoading }] = useMutation(UPDATE_VALUE_FIELD);
  const [loading, setLoading] = React.useState(deleteLoading || updateLoading);
  const [indicators, setIdicators] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const timer = React.useRef(0);

  React.useEffect(() => {
    setLoading(deleteLoading || updateLoading);
  }, [deleteLoading, updateLoading]);

  React.useEffect(() => {
    let temp = [];
    if (valueField.required) temp.push(valueFieldIndicators.required);
    if (valueField.multiline) temp.push(valueFieldIndicators.multiline);
    if (valueField.maxTextLength) temp.push(valueFieldIndicators.maxTextLength);
    if (isTypeAutoComplete(valueField.inputType)) temp.push(valueFieldIndicators.autoComplete);
    if (valueField.inputType === 'date') temp.push(valueFieldIndicators.date);

    setIdicators(temp);
  }, [valueField]);

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    }
  }, []);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleDelete = () => {
    deleteKey({ variables: {
      valueFieldId: valueField._id
    }}).then(res=>{
      // console.log(res);
    });
  }

  const handleUpdate = (update) => {
    return updateKey({ variables: {
      where: { _id: valueField._id },
      update
    }});
  }

  const updateReqired = (event) => {
    handleUpdate({
      required: event.target.checked
    }).then(res=>{
      console.log(res);
    });
  }

  const updateMultiLine = (event) => {
    handleUpdate({
      multiline: event.target.checked
    }).then(res=>{
      console.log(res);
    });
  }

  const handleMaxChange = (event) => {
    setMaxTextLength(event.target.value);
    clearTimeout(timer.current);
    timer.current = setTimeout(()=>{
      handleUpdate({
        maxTextLength: parseInt(event.target.value)
      }).then(res=>{
        console.log(res);
      });
    }, 1000);
  }

  const handleTypeChange = (event) => {
    handleUpdate({
      inputType: event.target.value
    }).then(res=>{
      console.log(res);
    });
  }

  const handleOpen = () => {
    setOpen(!open);
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <>
    <Grid container alignItems="center">
      <Grid item xs={9} sx={{padding: 1}}>
        <ValueFieldText text={valueField.name} update={handleUpdate} />
      </Grid>
      <Grid item xs={2}>
        <Grid container alignItems="center" >
          <IconButton onClick={handleOpen}>
            {open? <ExpandLessIcon />: <ExpandMoreIcon />}
          </IconButton>
          {indicators.map(indicator=>(
            <span>{indicator}&nbsp;</span>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={1}>
        <FuntionBtn valueField={valueField} count={count}
          handleDelete={handleDelete}
          inputId={inputId}
          loading={loading} />
        {/* <IconButton onClick={handleDelete} disabled={loading}>
          <ClearIcon />
        </IconButton> */}
      </Grid>
    </Grid>
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={2}>
          <FormControlLabel disabled={loading}
            control={
              <Switch checked={valueField.required} 
                onChange={updateReqired} />
            }
            label="Required" labelPlacement="top"
          />
        </Grid>
        <Grid item xs={2}>
          <FormControlLabel disabled={loading}
            control={
              <Switch checked={valueField.multiline} 
                onChange={updateMultiLine} />
            }
            label="Multiline" labelPlacement="top"
          />
        </Grid>
        <Grid item xs={4}>
          <TextField variant="outlined" size='small' fullWidth 
            label="Max length" 
            type='number'
            value={maxTextLength}
            onChange={handleMaxChange} />
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth disabled={loading} size="small">
            <InputLabel>Input type</InputLabel>
            <Select id="input-type" label="Input type" size="small"
              value={valueField.inputType}
              onChange={handleTypeChange}
            >
              <MenuItem value="text">Text</MenuItem>
              <MenuItem value="date">Date</MenuItem>
              <MenuItem value="autoComplete">Auto complete</MenuItem>
              <MenuItem value="autoComplete_Course_code">Course code</MenuItem>
              <MenuItem value="autoComplete_Course_name">Course name</MenuItem>
              <MenuItem value="autoComplete_Course_section">Course section</MenuItem>
              <MenuItem value="autoComplete_Course_level">Course level</MenuItem>
              <MenuItem value="autoComplete_Course_nature">Course nature</MenuItem>
              <MenuItem value="autoComplete_Service_key">Service key</MenuItem>
              <MenuItem value="autoComplete_Service_name">Service name</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      { valueField.inputType === 'autoComplete' &&
        <div>
          { valueField.options?.map(option=>(
            <p>{option}</p>
          ))
          }
        </div>
      }
    </Collapse>
    </>
  )
}
