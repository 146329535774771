import React from 'react';
import { CardContent, TextField, Button, Grid, Autocomplete, Table, TableBody, TableRow, TableCell, IconButton, TableHead } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery, useMutation } from "@apollo/client";
import { GET_CONST_STORE, UPDATE_CONST_STORE } from '../../constants/gqls/constStore';
import ConstFieldInput from './ConstFieldInput';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  cell: {
    width: '1%',
    whiteSpace: 'nowrap',
  },
  labelText: {
    color: theme.palette.secondary.main
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function ConstStoreSetting({label}) {
  const classes = useStyles();
  const [constFields, setConstFields] = React.useState([]);
  const {data} = useQuery(GET_CONST_STORE,
    { variables: { label }}
  );
  const [updateConstStore, { loading }] = useMutation(UPDATE_CONST_STORE);
  console.log(data);

  React.useEffect(() => {
    if (data && data.getConstStore) {
      setConstFields(data.getConstStore.fields || []);
    }
  }, [data]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  // const onTitleChange = (event) => {
  //   setTitle(event.target.value);
  //   setChanged(true);
  // }

  // const onCodeChange = (event) => {
  //   setCode(event.target.value);
  //   setChanged(true);
  // }

  // const onSectionChange = (event, newValue) => {
  //   if (newValue !== section) {
  //     setSection(newValue);
  //     setChanged(true);
  //   }
  // };

  // const onLevelChange = (event, newValue) => {
  //   if (newValue !== level) {
  //     setLevel(newValue);
  //     setChanged(true);
  //   }
  // };

  const onAddKeyField = () => {
    updateConstStore({ variables: {
      where: { label },
      update: { 
        fields: [{
          create: [{ node: { name: '' }}]
        }] 
      }
    }}).then(res => {
      console.log(res);
      
    });
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cell}>
                <span className={classes.labelText}>Fields:</span>
                <IconButton onClick={onAddKeyField} disabled={loading}>
                  <AddCircleIcon />
                </IconButton>
              </TableCell>
              <TableCell className={classes.cell}>
                <span className={classes.labelText}>Is unique</span>
              </TableCell>
              <TableCell className={classes.cell}>
                <span className={classes.labelText}>As filter</span>
              </TableCell>
              <TableCell className={classes.cell}>
                <span className={classes.labelText}>Allow other</span>
              </TableCell>
              <TableCell className={classes.cell}>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {constFields.map((field)=>(
              <ConstFieldInput key={field._id}
                field={field}  />
            ))}
          </TableBody>
        </Table>
    </CardContent>
  );
}
