import React from 'react';
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import AddCardIcon from '@mui/icons-material/AddCard';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from '@material-ui/core/styles';
import DeleteDialog from './DeleteDialog';
import SelectBoardDialog from '../BoardCards/SelectBoardDialog';
import AddCardDialog from '../BoardCards/AddCardDialog';

const actions = [
  { icon: <AddCardIcon />, name: 'Add card', open:'AddCard' },
  { icon: <DashboardIcon />, name: 'Select board', open:'SelectBoard' },
  { icon: <DeleteIcon />, name: 'Delete Space', open:'DeleteSpace' },
];

const cardOptions = [
  { name: 'Space Info', value: 'space-info' },
  { name: 'Space Members', value: 'space-members' },
  { name: 'Document Add', value: 'doc-add' },
  { name: 'Documents', value: 'space-documents' },
];

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  root: {
    "&.MuiSpeedDial-root": {
      // position: (props) => props.show ? 'relative' : 'fixed',
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    //   borderRadius: '20px',
    //   height: (props) => props.show ? '200px' : '40px',
    //   width: (props) => props.show ? '200px' : '40px',
    //   transition: 'width 2s, height 2s'
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function SettingBtn({space, showBoard, setShowBoard, board, refetchQueries}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState('');

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleClose = () => {
    setOpen('');
  };

  const handleCLick = (inOpen) => {
    setOpen(inOpen);
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <SpeedDial className={classes.root}
        ariaLabel="Board setting"
        icon={<SpeedDialIcon icon={<SettingsIcon />} />}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={()=>handleCLick(action.open)}
          />
        ))}
      </SpeedDial>
      <DeleteDialog space={space} open={open==='DeleteSpace'} handleClose={handleClose} />
      <AddCardDialog board={board} open={open==='AddCard'} handleClose={handleClose}
        cardOptions={cardOptions} refetchQueries={refetchQueries} />
      <SelectBoardDialog board={showBoard} setBoard={setShowBoard}
        open={open==='SelectBoard'} handleClose={handleClose}
        entity="space" />
    </React.Fragment>
  );
}
