import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { List, LinearProgress } from '@mui/material';
import { useQuery } from "@apollo/client";
import ServiceItem from './ServiceItem';
import { SERVICES } from '../../constants/gqls/services';
const limit = 10;

//******************************************************************************
// component definition
//******************************************************************************
export default function ServicesList() {
  const { loading, data, fetchMore } = useQuery(SERVICES, {
    variables: { options: { limit, offset: 0 }}
  });

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const loadMore = () => {
    fetchMore({ variables: { 
      options: {
        limit,
        offset: data.services.length
      }
    }});
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return <LinearProgress />;
  if (!data) return 'Network error.'
  if (data.servicesCount === 0) {
    return 'No service yet.';
  }

  return (
    <InfiniteScroll
        loadMore={loadMore}
        hasMore={data.servicesCount > data.services.length}
        loader={<LinearProgress key="loading" />}
    >
      <List>
        { data.services.map(service=>(
          <ServiceItem key={service._id} service={service} />
        )) }
      </List>
    </InfiniteScroll>
  );
}
