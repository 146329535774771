import React from 'react';
import { Grid, Button } from '@mui/material';
// import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from "@apollo/client";
import PeopleInput from '../InputsCommt/PeopleInput';
import MemberRoleSelect from './MemberRoleSelect';
import ImportUGCBtn from './ImportUGCBtn';
import ClearMembersBtn from './ClearMembersBtn';
import ImportMembersBySID from './ImportMembersBySID';
import { ME } from '../../constants/gqls/user';

//******************************************************************************
// style definition
//******************************************************************************
// const useStyles = makeStyles((theme) => ({
//
// }));

//******************************************************************************
// component definition
//******************************************************************************
export default function MembersAddInputs({space, handleUpdate, loading}) {
  // const classes = useStyles();
  const { data } = useQuery(ME);
  const [members, setMembers] = React.useState([]);
  const [role, setRole] = React.useState('Member');

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleChange = (event) => {
    setRole(event.target.value);
  };

  const handleCancel = () => {
    setMembers([]);
    setRole('Member');
  };

  const handleAdd = () => {
    handleUpdate({
      add: members.map(item=>item._id),
      role: role
    }, handleCancel);
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Grid container spacing={2} justifyContent="center">
      { data && data.me && data.me.roles.includes('admin') &&
        <>
          {space.name === 'UGC Students'?
            <Grid item xs={6}>
              <ImportUGCBtn spaceId={space._id} />
            </Grid>
            :
            <Grid item xs={6}>
              <ImportMembersBySID spaceId={space._id} />
            </Grid>
          }
          <Grid item xs={6}>
            <ClearMembersBtn spaceId={space._id} />
          </Grid>
        </>
      }
      <Grid item xs={members.length > 0 ? 10 : 12}>
        <PeopleInput value={members} onChange={setMembers}
          label="Add members" multiple={true} disabled={loading} />
      </Grid>
      { members.length > 0 &&
        <React.Fragment>
          <Grid item xs={2}>
            <MemberRoleSelect role={role} handleChange={handleChange} />
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={handleCancel}>Cancel</Button>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={handleAdd}>Add</Button>
          </Grid>
        </React.Fragment>
      }
    </Grid>
  )
}
