import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';


//******************************************************************************
// component definition
//******************************************************************************
export default function DropdownInput({id, options, disabled, data, updateData}) {
  // const [value, setValue] = React.useState('');
  const value = data?.options[0]?._id || '';

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleChange = (event) => {
    // setValue(event.target.value);
    updateData({
      optionIds: [event.target.value],
    }).then(res => {
      // console.log(res);
    });
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <FormControl fullWidth id={id} disabled={disabled}>
      <InputLabel>Select</InputLabel>
      <Select id={'dropdown-'+id} label="Select"
        value={value} onChange={handleChange}
      >
        { options.map(option=>{
          let full = Boolean(option.quotaLeft<=0 && option.quotaLeft !== null && option.quotaLeft !== undefined);
          return (
            <MenuItem key={option.value} value={option.value} 
              disabled={disabled || full}>
              {option.label + (full? ' (full)' : '')}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}
