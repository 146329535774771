import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Grid, Button, CardContent } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { makeStyles } from '@material-ui/core/styles';

function formatData(rawData, dataColumns) {
  // console.log(rawData);
  // console.log(dataColumns);
  if (!rawData || !dataColumns) return [[],[]];

  // errors
  let errs = [];

  // columns
  let columns = dataColumns.map(col=>({ 
    headerName: col.name,
    field:  col.field
  }));
  // console.log(columns);

  // rows
  let data = rawData.map((item, index)=>{
    let dataItem = { id: item._id || index };
    for (let i = 0; i < dataColumns.length; i++) {
      const col = dataColumns[i];
      if (col.code) {
        try {
          // eslint-disable-next-line
          dataItem[col.field] = eval('item.'+col.code);
        } catch (error) {
          console.log('item.'+col.code);
          console.log(error);
          errs.push(error.message);
        }
      } else {
        dataItem[col.field] = item[col.field];
      }
    }
    return dataItem;
  });

  return {columns, data, errs};
}

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
    height: 740
  },
  label: {
    marginRight: theme.spacing(2)
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function DataTable({tableName, rawData, dataColumns, setEditMode}) {
  const classes = useStyles();
  const gridRef = React.useRef();
  const [table, setTable] = React.useState([[], []]);

  // never changes, so we can use useMemo
  const defaultColDef = React.useMemo(() => ({
    resizable: true,
    sortable: true,
    filter: true
  }), []);

  // when changes
  React.useEffect(() => {
    setTable(formatData(rawData, dataColumns));
  }, [rawData, dataColumns]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleExport = () => {
    gridRef.current.api.exportDataAsCsv({fileName: tableName});
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <>
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            <Button variant="outlined" startIcon={<ArrowBackIosIcon />}
              onClick={setEditMode}>
              Edit table
            </Button>
          </Grid>
          <Grid item xs="auto">
            <Button variant="contained" color="secondary"
              startIcon={<DownloadIcon />}
              onClick={handleExport}>
                Download CSV
            </Button>
          </Grid>
        </Grid>
      </CardContent>
      <div className={classes.table}>
        <AgGridReact className="ag-theme-alpine"
          ref={gridRef}
          animateRows="true"
          rowData={table.data}
          columnDefs={table.columns}
          defaultColDef={defaultColDef}
        />
      </div>
    </>
  );
}
