import React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

//******************************************************************************
// component definition
//******************************************************************************
export default function DateFormattedInput({value, onChange, label, id, fullWidth, disabled}) {

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleDateChange = (date) => {
    let dateString = date.getDate() +" "+ months[date.getMonth()] +" "+ date.getFullYear() ;
    // console.log(dateString);
    onChange(dateString);
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        renderInput={(props) => <TextField fullWidth={fullWidth} id={id} {...props} />}
        label={label}
        value={value}
        onChange={handleDateChange}
        disabled={disabled}
        format={"dd MMM yyyy"}
      />
    </LocalizationProvider>
  );
}
