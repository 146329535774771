import React from 'react';
import { Grid } from '@mui/material';
// import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from "@apollo/client";
import FileUpload from '../Files/FileUpload';
import FileDeleteBtn from '../Files/FileDeleteBtn';
import { gql } from '@apollo/client';

const DOCUMENT = gql`
  query Document($docId: ID!) {
    document(id: $docId) {
      _id hasPdfTemplate pdfTemplate { _id uploader { _id name }}
    }
  }
`;

//******************************************************************************
// style definition
//******************************************************************************
// const useStyles = makeStyles((theme) => ({
//   row: {
//     paddingTop: theme.spacing(1)
//   },
//   dateTiem: {
//     minWidth: '50%'
//   },
//   actions: {
//     paddingTop: theme.spacing(1),
//     display: 'flex',
//     "& .MuiButton-root": {
//       margin: 'auto'
//     }
//   },
// }));

//******************************************************************************
// component definition
//******************************************************************************
export default function FormExportAdmin({docId}) {
  const { data, refetch } = useQuery(DOCUMENT, {variables: { docId }});
  // console.log(data);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={12}>Template file: </Grid>
      <Grid item>
        <FileUpload hasFile={Boolean(data?.document?.pdfTemplate)}
          systemFileType="formTemplate"
          relatedId={docId}
          fileTypes={["text/html"]}
          refetch={refetch}>
            Uploaded by {data?.document?.pdfTemplate?.uploader.name}
          </FileUpload>
      </Grid>
      <Grid item>
        <FileDeleteBtn fileId={data?.document?.pdfTemplate?._id} refetch={refetch} />
      </Grid>
    </Grid>
  );
}
