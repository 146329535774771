import React from 'react';
import { Grid, Button, CardContent } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { makeStyles } from '@material-ui/core/styles';
import ServiceSessionCell from './ServiceSessionCell';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

function formatData(rawData) {
  // console.log(rawData);
  if (!rawData) return [[],[]];

  // columns
  let columns = [{
    headerName: 'Service session',
    field: 'id',
    width: 150,
    cellRenderer: ServiceSessionCell
  }, {
    headerName: 'Semester',
    field: 'semester'
  }, {
    headerName: 'Status',
    field: 'status'
  }, {
    headerName: 'Hours (completed)',
    field: 'hoursCompleted'
  }, {
    headerName: 'Hours (total)',
    field: 'hoursTotal'
  }, {
    headerName: 'Service providers',
    field: 'providers'
  }, {
    headerName: 'Service users',
    field: 'users'
  }, {
    headerName: 'Meeting time',
    field: 'meetingTime'
  }];

  let filters = rawData.services[0].serviceFormFilters;
  for (var i = 0; i < filters.length; i++) {
    columns.push({
      headerName: filters[i].itemText,
      field: filters[i]._id
    });
  }

  // rows
  let data = rawData.serviceSessionsByYear.map(item=>{
    let dataItem = {
      id: item._id,
      semester: item.semester,
      status: item.status,
      hoursCompleted: item.hoursCompleted,
      meetingTime: item.meetingTime,
      hoursTotal: item.status === 'Closed'? item.hoursCompleted : item.hours,
      providers: item.providers.map(pItem=>`${pItem.name} (${pItem.email})`).join(', '),
      users: item.users.map(pItem=>`${pItem.name} (${pItem.email})`).join(', '),
    };
    for (var i = 0; i < item.refFilterData.length; i++) {
      dataItem[item.refFilterData[i].input._id] = item.refFilterData[i].options.map(oItem=>oItem.text).join(', ');
    }
    return dataItem;
  });

  return {columns, data};
}

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
    height: 740
  },
  label: {
    marginRight: theme.spacing(2)
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function DataTable({tableName, data}) {
  const classes = useStyles();
  const gridRef = React.useRef();
  const [tableData, setData] = React.useState([[], []]);
  // const [applicantAttributes, setApplicantAttributes] = React.useState({
  //   email: false,
  //   studyLevel: false,
  // });
  // console.log(data);

  // never changes, so we can use useMemo
  const defaultColDef = React.useMemo(() => ({
    resizable: true,
    sortable: true,
    filter: true
  }), []);

  // when changes
  React.useEffect(() => {
    setData(formatData(data));
  }, [data]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleExport = () => {
    gridRef.current.api.exportDataAsCsv({fileName: tableName});
  }

  // const handleChange = (event) => {
  //   setApplicantAttributes({
  //     ...applicantAttributes,
  //     [event.target.name]: event.target.checked,
  //   });
  // };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <>
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            <div />
          </Grid>
          <Grid item xs="auto">
            <Button variant="contained" color="secondary"
              startIcon={<DownloadIcon />}
              onClick={handleExport}>
                Download CSV
            </Button>
          </Grid>
        </Grid>
      </CardContent>
      <div className={classes.table}>
        <AgGridReact className="ag-theme-alpine"
          ref={gridRef}
          animateRows="true"
          rowData={tableData.data}
          columnDefs={tableData.columns}
          defaultColDef={defaultColDef}
        />
      </div>
    </>
  );
}
