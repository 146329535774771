import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, Grid } from '@mui/material';
import TimeInput from './TimeInput';
import DateFormattedInput from '../InputsCommt/DateFormattedInput';

const dayOptions = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

//******************************************************************************
// component definition
//******************************************************************************
export default function MeetingDateTimeInput({value, onChange, loading}) {
  const [date, time] = value? value.split(', ') : ['', ''];

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleDateChange = (dateValue) => {
    if (onChange) {
      onChange([dateValue, time].join(', '));
    }
  };

  const handleTimeChange = (TimeValue) => {
    if (onChange) {
      onChange([date, TimeValue].join(', '));
    }
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <DateFormattedInput label="Meeting Date"
          value={date} 
          onChange={handleDateChange} id="meeting-date"
          fullWidth={true} />
      </Grid>
      <Grid item xs={12} md={4}>
        <TimeInput loading={loading}
           value={time} handleChange={handleTimeChange} />
      </Grid>
    </Grid>
  )
}
