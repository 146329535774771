import React from 'react';
import { Container, Paper, Tabs, Tab } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from "@apollo/client";
import { doRefetch } from '../constants/cache';
import MySpacesList from '../components/Spaces/MySpacesList';
import SpaceAdd from '../components/Spaces/SpaceAdd';
import Search from '../components/Spaces/Search';
import { ME } from '../constants/gqls/user';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3)
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function Spaces() {
  const classes = useStyles();
  const { data } = useQuery(ME);
  const [value, setValue] = React.useState(0);
  const [tabs, setTabs] = React.useState([{
    label: 'My Spaces',
    component: <MySpacesList />,
  }]);

  React.useEffect(() => {
    if (data?.me.roles.includes('admin')) {
      setTabs([{
        label: 'My Spaces',
        component: <MySpacesList />,
      }, {
        label: 'Search',
        component: <Search />,
      }, {
        label: 'Create',
        component: <SpaceAdd saveThen={goMySpaces} />,
      }])
    } else if(data?.me.roles.includes('staff')){
      setTabs([{
        label: 'My Spaces',
        component: <MySpacesList />,
      }, {
        label: 'Search',
        component: <Search />,
      }])
    } else {
      setTabs([{
        label: 'My Spaces',
        component: <MySpacesList />,
      }])
    }
  }, [data]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const goMySpaces = () => {
    doRefetch(true);
    setValue(0);
  };

  // const tabs = [{
  //   label: 'My Spaces',
  //   component: <MySpacesList />,
  // }, {
  //   label: 'Search',
  //   component: <Search />,
  // }, {
  //   label: 'Create',
  //   component: <SpaceAdd saveThen={goMySpaces} />,
  // }]

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Container maxWidth="xl" className={classes.container}>
      <Paper>
        <Tabs value={value} onChange={handleChange}>
          { tabs.map((tab, i)=>(
            <Tab key={i} label={tab.label}/>
          )) }
        </Tabs>
        {tabs[value].component}
      </Paper>
    </Container>
  );
}
