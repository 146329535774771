import React from 'react';
import { useParams } from "react-router-dom";
import { Container, Paper, LinearProgress, CardHeader } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from "@apollo/client";
import CreateOrEdit from '../components/Courses/CreateOrEdit';
import FunctionBtn from '../components/Courses/FunctionBtn';
import NotFount from './NotFount';
import { COURSE } from '../constants/gqls/courses';
import { ME } from '../constants/gqls/user';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3)
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function Course() {
  const classes = useStyles();
  const params = useParams();
  const [mode, setMode] = React.useState('view');
  const queryMe = useQuery(ME);
  const { loading, data } = useQuery(COURSE, { variables: {
    where: { OR: [
      { _id: params.code },
      { code: params.code }
    ]},
  }});

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const setModeView = () => setMode('view');


  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return <LinearProgress />;
  if (!data) return 'Network error.';
  if (data.courses.length === 0) return <NotFount />;

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Paper>
        <CardHeader title={`${data.courses[0].name}`} action={
          queryMe.data?.me.roles.includes('admin')?
          <FunctionBtn courseId={data.courses[0]._id}
            mode={mode} setMode={setMode} />
          : null
        } />
        <CreateOrEdit course={data.courses[0]}
          then={setModeView} mode={mode} />
      </Paper>
    </Container>
  );
}
