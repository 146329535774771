import React from 'react';
import { useSearchParams } from "react-router-dom";
import { Accordion, AccordionSummary, Typography, AccordionDetails, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import { makeStyles } from '@material-ui/core/styles';
import FormExportBtn from './FormExportBtn';
import FormExportAdmin from './FormExportAdmin';


//******************************************************************************
// style definition
//******************************************************************************
// const useStyles = makeStyles((theme) => ({
//   row: {
//     paddingTop: theme.spacing(1)
//   },
//   dateTiem: {
//     minWidth: '50%'
//   },
//   actions: {
//     paddingTop: theme.spacing(1),
//     display: 'flex',
//     "& .MuiButton-root": {
//       margin: 'auto'
//     }
//   },
// }));

//******************************************************************************
// component definition
//******************************************************************************
export default function FormExport({docId, myRole}) {
  const [searchParams] = useSearchParams();
  // const classes = useStyles();
  const [profileId, setProfileId] = React.useState(null);
  const [open, setOpen] = React.useState(true);

  React.useEffect(() => {
    if (searchParams.get("profile")) {
      setProfileId(searchParams.get("profile"));
    } else {
      setProfileId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleClick = () => {
    setOpen(!open);
  };

  // const handleOTChange = (formOpen) => {
    
  // }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Accordion expanded={open} onChange={handleClick}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Export form</Typography>
      </AccordionSummary>
      <AccordionDetails>
        { (myRole === 'owner' || myRole === 'admin') &&
          <FormExportAdmin docId={docId} />
        }
        <div><p></p></div>
        <Grid container spacing={1} justifyContent="center" alignItems="center">
          <Grid item>
            <FormExportBtn formId={docId} 
              applicantId={profileId} />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
