import React from 'react';
import { FormControl, RadioGroup, FormControlLabel, Radio, TextField } from '@mui/material';


//******************************************************************************
// component definition
//******************************************************************************
export default function RadioInput({id, options, other, disabled, data, updateData}) {
  const [value, setValue] = React.useState(data?.options[0]?._id || null);
  const [text, setText] = React.useState(data?.value || '');
  const [changed, setChanged] = React.useState(false);
  // console.log(value);

  React.useEffect(() => {
    // console.log(data);
    setText(data?.value || '');
    if (data?.value) {
      if (data.value !== 'filterNot') {
        setValue('other');
      } else {
        setValue(data.value);
        setText('')
      }
    } else {
      setValue(data?.options[0]?._id || null);
    }
  }, [data]);
  // console.log(value);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleChange = (event) => {
    // if filterNot, no option is selected
    let updatePrams;
    if (event.target.value === 'filterNot') {
      updatePrams = {
        optionIds: [],
        value: 'filterNot'
      }
    } else {
      updatePrams = {
        optionIds: [event.target.value],
        value: ''
      }
    }

    updateData(updatePrams).then(res => {
      // console.log(res);
      if (event.target.value === 'other') {
        setValue('other');
      }
    });
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
    setChanged(true)
  };

  const handleBlur = () => {
    if (changed) {
      updateData({ value: text }).then(res => {
        // console.log(res);
        setChanged(false);
      })
    }
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <FormControl fullWidth id={id} disabled={disabled}>
      <RadioGroup name={'radio-'+id}
        value={value} onChange={handleChange}
      >
        { options.map(option=>{
          let full = Boolean(option.quotaLeft<=0 && option.quotaLeft !== null && option.quotaLeft !== undefined);
          return (
            <FormControlLabel key={option.value}
              value={option.value}
              control={<Radio />}
              label={option.label + (full? ' (full)' : '')}
              disabled={disabled || full}
            />
          )
        })}
        { other &&
          <FormControlLabel value="other" control={<Radio />} label="Other" />
        }
        { value === 'other' &&
          <TextField fullWidth id={'other-'+id}
            variant="standard" disabled={disabled}
            value={text} onChange={handleTextChange} onBlur={handleBlur} />
        }
      </RadioGroup>
    </FormControl>
  )
}
