import React from 'react';
import { TextField, Grid } from '@mui/material';
import ServiceInput from '../Services/ServiceInput';
import ServiceSessionYearSelect from './ServiceSessionYearSelect';


//******************************************************************************
// component definition
//******************************************************************************
export default function ServiceSessionWhereEdit({where, setWhere, setChanged, disabled}) {
  const [service, setService] = React.useState(null);
  const [year, setYear] = React.useState(null);
  const init = React.useRef(true);
  // console.log(service, year);

  React.useEffect(() => {
    if (init.current || !where) {
      let whereObj = {};
      try {
        whereObj = JSON.parse(where);
        setService({...whereObj.service, name: ''});
        setYear(whereObj.year);
      } catch (error) {
        console.log(error.message);
      }
    } 
  }, [where]);

  React.useEffect(() => {
    if (!init.current) {
      let whereObj = {};
      if (year) {
        whereObj.year = year
      }
      if (service) {
        whereObj.service = {
          key: service.key
        }
      }
      setWhere(JSON.stringify(whereObj, null, 2));
      setChanged(true);
    } else {
      init.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service, year]);


  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onWhereChange = (event) => {
    setWhere(event.target.value);
    setChanged(true);
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <div>
          <ServiceInput label="Service" disabled={disabled} 
            value={service} onChange={setService} />
          <ServiceSessionYearSelect disabled={disabled} 
            value={year} onChange={setYear} />
        </div>
      </Grid>
      <Grid item xs={6}>
        <TextField fullWidth multiline rows={8} variant="outlined"
          id="where-statement" label="Where statement" 
          value={where} onChange={onWhereChange}
          disabled={disabled}  />
      </Grid>
    </Grid>
  );
}
