import React from 'react';
import { useParams, generatePath } from "react-router-dom";
import { Typography, CardContent, CardActions, TextField,
  Table, TableBody, TableRow, TableCell } from '@mui/material';
// import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from "@apollo/client";
import SessionSave from './SessionSave';
import SessionFunctions from './SessionFunctions';
import MeetingTimeInput from './MeetingTimeInput';
import HtmlEditor from "../ContentEditor/HtmlEditor";
import { DateTimeToString } from '../../constants/utils';
import { UPDATE_SERVICE_SESSION } from '../../constants/gqls/serviceSessions';
import { GET_STUDENT_PRIVATE_INFO } from '../../constants/gqls/studentPrivateInfo';
import SiteMap from '../../constants/siteMap';
import ParticipantsItem from './ParticipantsItem';

function getParticipantsByRoles(participants, studentForms) {
  // console.log(participants);
  let forms = {};
  for (let i = 0; i < studentForms.length; i++) {
    let [id, form] = studentForms[i].split(':');
    let [formId, subId] = form.split('/');
    forms[id] = generatePath(SiteMap.document, { id: formId+'?submission='+subId });
  }
  
  let roles = {};
  for (var i = 0; i < participants.length; i++) {
    if (!roles[participants[i].role]) {
      roles[participants[i].role] = []
    }
    roles[participants[i].role].push({
      _id: participants[i].node._id,
      name: `${participants[i].node.name} <${participants[i].node.email}>`,
      formPath: forms[participants[i].node._id]
    });
  }
  // console.log(roles);

  let participantsByRoles = [];
  if (roles.Teacher) {
    participantsByRoles.push({
      role: 'Teacher',
      participants: roles.Teacher
    })
  }
  if (roles.Student) {
    participantsByRoles.push({
      role: 'Student',
      participants: roles.Student
    })
  }
  // let formPath = null;
  // if (studentForms) {
  //   let [formId, subId] = studentForms.split('/');
  //   formPath = generatePath(SiteMap.document, { id: formId+'?submission='+subId });
  // }
  // let participantsByRoles = [];
  // if (roles.Teacher) {
  //   participantsByRoles.push({
  //     role: 'Teacher',
  //     names: roles.Teacher.join(', ')
  //   });
  // }
  // if (roles.Student) {
  //   participantsByRoles.push({
  //     role: 'Student',
  //     names: roles.Student.join(', '),
  //     formPath
  //   });
  // }
  return participantsByRoles;
}

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  cell: {
    width: '1%',
    whiteSpace: 'nowrap',
  },
  note: {
    fontFamily: 'inherit'
  },
  labelText: {
    color: theme.palette.secondary.main
  },
  formLink: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontWeight: 'bold'
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function SessionDetails({serviceSession, editMode, editClicked}) {
  const classes = useStyles();
  const params = useParams();
  const [year, setYear] = React.useState(serviceSession.year);
  const [semester, setSemester] = React.useState(serviceSession.semester);
  const [note, setNote] = React.useState(serviceSession.note);
  const [hours, setHours] = React.useState(serviceSession.hours);
  const [location, setLocation] = React.useState('');
  const [preTestResult, setPreTestResult] = React.useState('');
  const [postTestResult, setPostTestResult] = React.useState('');
  const [canRemoveStudent, setCanRemoveStudent] = React.useState(false);
  const [updateServiceSession, { loading }] = useMutation(UPDATE_SERVICE_SESSION, {
    refetchQueries: [GET_STUDENT_PRIVATE_INFO]
  });
  const participantsByRoles = getParticipantsByRoles(serviceSession.participantsConnection?.edges || [], serviceSession.studentForms);
  console.log(serviceSession);


  React.useEffect(() => {
    setPreTestResult(serviceSession.preTestResult || '');
    setPostTestResult(serviceSession.postTestResult || '');
    setLocation(serviceSession.location || '');
    setCanRemoveStudent(serviceSession.myRole==="admin" || serviceSession.myRole==="manager");
  }, [serviceSession]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleNoteChange = (value) => {
    if (value !== note) {
      setNote(value);
    }
  }

  const handleYearChange = (event) => {
    setYear(event.target.value)
  }

  const handleSemesterChange = (event) => {
    if (event.target.value >= 1 && event.target.value <= 2) {
      setSemester(event.target.value)
    }
  }

  const handleHoursChange = (event) => {
    if (event.target.value > 0) {
      setHours(event.target.value);
    }
  }

  const handleLocationChange = (event) => {
    setLocation(event.target.value)
  }

  const handlePreTestResultChange = (event) => {
    setPreTestResult(event.target.value)
  }

  const handlePostTestResultChange = (event) => {
    setPostTestResult(event.target.value)
  }

  const handleUpdate = (inUpdate) => {
    console.log(inUpdate);
    return updateServiceSession({ variables: {
      id: params.id,
      inUpdate
    }});
  }

  const handleSave = () => {
    handleUpdate({
      year, note, location, preTestResult, postTestResult,
      hours: parseFloat(hours),
      semester: parseInt(semester)
    }).then(res=>{
      editClicked();
    });
  }

  const removeStudent = (_id) => {
    handleUpdate({
      participants: {
        disconnect: [{
          where: {
            node: {
              _id
            }
          }
        }]
      }
    }).then(res=>{
      // console.log(res);
    });
  }

  const handleMeetingTimeChange = (meetingTime) => {
    handleUpdate({
      meetingTime
    }).then(res => {
      // console.log(res);
    });
  }


  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell className={classes.cell}>
              <span className={classes.labelText}>Category:</span>
            </TableCell>
            <TableCell>{serviceSession.categories.join(' ')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cell}>
              <span className={classes.labelText}>Status:</span>
            </TableCell>
            <TableCell>{serviceSession.status}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cell}>
              <span className={classes.labelText}>Created at:</span>
            </TableCell>
            <TableCell>{DateTimeToString(serviceSession.createdAt)}</TableCell>
          </TableRow>
          { participantsByRoles.map(item=>(
            <TableRow key={item.role}>
              <TableCell className={classes.cell}>
                <span className={classes.labelText}>{item.role}:</span>
              </TableCell>
              <TableCell>
                {item.participants.map((pp, i)=>(
                  <ParticipantsItem key={pp._id} 
                    role={item.role}
                    participant={pp}
                    serviceSession={serviceSession}
                    isLast={i===item.participants.length-1}
                    removeStudent={(canRemoveStudent && serviceSession.removableStudentIds?.includes(pp._id) && item.participants.length>1)? removeStudent: null}
                    loading={loading}
                  />
                ))}
              </TableCell>
            </TableRow>
          )) }
          { (serviceSession.myRole === 'admin' || serviceSession.myRole==='manager') &&
            <>
              <TableRow>
                <TableCell className={classes.cell}>
                  <span className={classes.labelText}>Acadmic Year:</span>
                </TableCell>
                <TableCell>
                  { editMode? 
                    <TextField id="service-session-year" disabled={loading}
                      value={year} onChange={handleYearChange}  />
                    : serviceSession.year
                  }
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.cell}>
                  <span className={classes.labelText}>Semester:</span>
                </TableCell>
                <TableCell>
                  { editMode? 
                    <TextField id="service-session-semester" disabled={loading}
                      type="number" step="1"
                      value={semester} onChange={handleSemesterChange} />
                    : serviceSession.semester
                  }
                </TableCell>
              </TableRow>
            </>
          }
          <TableRow>
            <TableCell className={classes.cell}>
              <span className={classes.labelText}>Hours:</span>
            </TableCell>
            <TableCell>
              { editMode? 
                <TextField id="service-session-hours" disabled={loading}
                  type="number" step="0.5"
                  value={hours} onChange={handleHoursChange}  />
                : serviceSession.hoursCompleted+' / '+serviceSession.hours
              }
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cell}>
              <span className={classes.labelText}>Meeting time:</span>
            </TableCell>
            <TableCell>{
              editMode?
              <MeetingTimeInput value={serviceSession.meetingTime}
                onChange={handleMeetingTimeChange} loading={loading}
                type={serviceSession.nonrepeatable? 'nonrepeatable' : 'weekly'} />
              :
              serviceSession.meetingTime
            }</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cell}>
              <span className={classes.labelText}>Location:</span>
            </TableCell>
            <TableCell>{
              editMode?
              <TextField id="ss-location" disabled={loading}
                value={location} onChange={handleLocationChange}  />
              :
              serviceSession.location
            }</TableCell>
          </TableRow>
          {serviceSession.preTestResult && serviceSession.postTestResult &&
            <>
              <TableRow>
                <TableCell className={classes.cell}>
                  <span className={classes.labelText}>Pre-test:</span>
                </TableCell>
                <TableCell>{
                  editMode?
                  <TextField id="ss-pretest-result" disabled={loading}
                    value={preTestResult} onChange={handlePreTestResultChange}  />
                  :
                  serviceSession.preTestResult
                }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.cell}>
                  <span className={classes.labelText}>Post-test:</span>
                </TableCell>
                <TableCell>{
                  editMode?
                  <TextField id="ss-posttest-result" disabled={loading}
                    value={postTestResult} onChange={handlePostTestResultChange}  />
                  :
                  serviceSession.postTestResult
                }</TableCell>
              </TableRow>
            </>
          }
        </TableBody>
      </Table>
      <CardContent>
        <Typography className={classes.labelText}>
          Report and remarks:
        </Typography>
        <HtmlEditor placeholder="Report or remarks goes here"
          html={serviceSession.note} onChange={handleNoteChange}
          readOnly={!editMode} disabled={loading} />
      </CardContent>
      <CardActions>
        { editMode?
          <SessionSave handleCancel={editClicked}
            handleSave={handleSave} loading={loading} /> :
          <SessionFunctions session={serviceSession}
            handleUpdate={handleUpdate} loading={loading} />
        }
      </CardActions>
    </>
  );
}
