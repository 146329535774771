import React from 'react';
import { TextField, IconButton, TableRow, TableCell, FormControlLabel, Switch } from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useMutation } from "@apollo/client";
import { UPDATE_CONST_FIELD, DELETE_CONST_FIELD, GET_CONST_STORE } from '../../constants/gqls/constStore';


//******************************************************************************
// component definition
//******************************************************************************
export default function ConstFieldInput({field}) {
  const [name, setName] = React.useState('');
  // const [isUnique, setIsUnique] = React.useState(false);
  // const [asFilter, setAsFilter] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const timer = React.useRef(0);
  const [updateConstField, { loading: updateLoading }] = useMutation(UPDATE_CONST_FIELD);
  const [deleteConstField, { loading: deleteLoading }] = useMutation(DELETE_CONST_FIELD, {
    refetchQueries: [GET_CONST_STORE]
  });

  React.useEffect(() => {
    setName(field.name || '');
    // setIsUnique(field.isUnique || false);
    // setAsFilter(field.isUnique || false);
    // setAllowOther(field.isUnique || false);
  }, [field]);

  React.useEffect(() => {
    setLoading(updateLoading || deleteLoading);
  }, [updateLoading, deleteLoading]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const fieldUpdate = ({name, isUnique, asFilter, allowOther}) => {
    updateConstField({
      variables: {
        where: { _id: field._id },
        update: { name, isUnique, asFilter, allowOther }
      }
    }).then(res=>{
      // console.log('field updated', res);
    });
  }

  const onNameChange = (event) => {
    setName(event.target.value);
  }

  const onUniqeChange = (event) => {
    // setIsUnique(event.target.checked);
    clearTimeout(timer.current);

    let isUnique = event.target.checked;
    let asFilter = undefined, allowOther = undefined;
    if (isUnique) {
      asFilter = false;
      allowOther = false;
    }
    fieldUpdate({name, isUnique, asFilter, allowOther});
  }

  const onAsFilterChange = (event) => {
    // setIsUnique(event.target.checked);
    clearTimeout(timer.current);

    let asFilter = event.target.checked;
    let isUnique = undefined;
    if (asFilter) {
      isUnique = false;
    }
    fieldUpdate({name, isUnique, asFilter});
  }

  const onAllowOtherChange = (event) => {
    // setIsUnique(event.target.checked);
    clearTimeout(timer.current);

    let allowOther = event.target.checked;
    fieldUpdate({name, allowOther});
  }

  const onDeleteKeyField = () => {
    deleteConstField({
      variables: { where: { _id: field._id } }
    });
  }

  const updateName = () => {
    clearTimeout(timer.current);
    timer.current = setTimeout(()=>{
      fieldUpdate({name});
    }, 800);
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <TableRow>
      <TableCell>
        <TextField fullWidth margin="dense" size="small"
           variant="outlined" label="Field name"
          value={name} onChange={onNameChange}
          onBlur={updateName}
          disabled={loading} />
      </TableCell>
      <TableCell>
        <FormControlLabel control={
            <Switch checked={field.isUnique}
              onChange={onUniqeChange} />
          } disabled={loading} />
      </TableCell>
      <TableCell>
        <FormControlLabel control={
            <Switch checked={field.asFilter}
              onChange={onAsFilterChange} />
          } disabled={loading} />
      </TableCell>
      <TableCell>
        <FormControlLabel control={
            <Switch checked={field.allowOther}
              onChange={onAllowOtherChange} />
          } disabled={loading || !field.asFilter} />
      </TableCell>
      <TableCell>
        <IconButton size="small" onClick={onDeleteKeyField} disabled={loading}>
          <RemoveCircleIcon fontSize="inherit" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
